<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="contact_wrap" v-if="pageIsLoaded">
            <div class="data">
                <h1 class="title medium" data-inview="fadeInUp" data-delay="200">
                    {{ $t('Nous joindre') }}
                </h1>
                <div class="contact" data-inview="fadeInUp" data-delay="300">
                    <a
                        :href="
                            'https://www.google.com/maps/dir//' +
                            globals.footer.informationsDeContact.adresse.replace('<br />', ' ')
                        "
                        target="_blank"
                        class="line_link"
                    >
                        <p class="regular-text">{{ $t('Adresse') }} - {{ $t('Montréal') }}</p>
                        <p class="regular-text link">
                            <span v-html="globals.footer.informationsDeContact.adresse"></span>
                        </p>
                    </a>
                    <a
                        :href="
                            'https://www.google.com/maps/dir//' +
                            globals.footer.informationsDeContact.adresse2.replace('<br />', ' ')
                        "
                        target="_blank"
                        class="line_link"
                    >
                        <p class="regular-text">{{ $t('Adresse') }} - {{ $t('Québec') }}</p>
                        <p class="regular-text link">
                            <span v-html="globals.footer.informationsDeContact.adresse2"></span>
                        </p>
                    </a>
                    <a
                        :href="'tel:' + globals.footer.informationsDeContact.telephone"
                        class="line_link"
                    >
                        <p class="regular-text">{{ $t('Téléphone') }}</p>
                        <p class="regular-text link">
                            <span v-html="globals.footer.informationsDeContact.telephone"></span>
                        </p>
                    </a>
                    <a
                        :href="'mailto:' + globals.footer.informationsDeContact.courriel"
                        class="line_link"
                    >
                        <p class="regular-text">{{ $t('Courriel') }}</p>
                        <p class="regular-text link">
                            <span v-html="globals.footer.informationsDeContact.courriel"></span>
                        </p>
                    </a>
                </div>
                <div class="map" data-inview="fadeInUp" data-delay="400">
                    <!-- <img class="ph" src="@/assets/img/pin-2.svg" alt="Projets terminés" /> -->
                    <div id="map"></div>
                </div>
            </div>
            <div class="form_contact">
                <h3 class="title medium">{{ $t('Parlons-en') }}</h3>

                <form
                    action=""
                    class="form"
                    name="contact"
                    method="post"
                    accept-charset="UTF-8"
                    @submit="sendForm($event)"
                >
                    <input
                        type="text"
                        :placeholder="$t('Prénom et nom')"
                        class="input"
                        name="name"
                        v-model="formData.nom"
                        required
                    />
                    <input
                        type="text"
                        :placeholder="$t('Téléphone')"
                        class="input"
                        name="name"
                        v-model="formData.telephone"
                        required
                    />
                    <input
                        type="email"
                        :placeholder="$t('Courriel')"
                        class="input"
                        name="name"
                        v-model="formData.courriel"
                        required
                    />
                    <textarea
                        placeholder="Message"
                        class="input textarea"
                        name="name"
                        v-model="formData.message"
                        required
                    ></textarea>
                    <div class="button_wrap">
                        <button class="cta">
                            <p class="regular-text">{{ $t('Envoyer') }}</p>
                            <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                        </button>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'
// import Flickity from 'flickity'

export default {
    data() {
        return {
            lightboxelement: null,
            formMessage: '',
            formMessageStatus: '',
            isSending: false,
            formData: {
                nom: '',
                // prenom: '',
                telephone: '',
                courriel: '',
                // heures: '',
                message: '',
            },
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        const scriptId = 'mapInitEl'
                        const mapAlreadyAttached = !!document.getElementById(scriptId)

                        if (mapAlreadyAttached) {
                            if (window.google) {
                                this.initGoogleMap()
                            }
                        } else {
                            window.mapApiInitialized = this.initGoogleMap

                            const script = document.createElement('script')
                            script.id = scriptId
                            script.src =
                                'https://maps.googleapis.com/maps/api/js?key=AIzaSyBqI-I62_T6zmJ7LtXNo4nT9ajaDf_-n_s&libraries=places,geometry&callback=mapApiInitialized'
                            document.body.appendChild(script)
                        }

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        servicesList() {
            if (!this.globalsIsLoaded) {
                return []
            }

            return this.globals.servicesEntry.data.slice(0, 4)
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            // this.formData[this.content.content.form.csrfName] = this.content.content.form.csrf
            this.formData['action'] = 'contact-form/send'
            this.formData['formName'] = 'Contact'
            this.formData['sujet'] = 'Contact'

            const formData = new FormData()
            formData.append('nom', this.formData.nom)
            // formData.append('prenom', this.formData.prenom)
            formData.append('telephone', this.formData.telephone)
            formData.append('courriel', this.formData.courriel)
            // formData.append('heures', this.formData.heures)
            formData.append('message', this.formData.message)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(
                    `${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`,
                    formData,
                    config
                )
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: 'Votre message a été envoyé avec succès.',
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    setTimeout(() => {
                        this.formData = {}

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.formData = {
                            fromName: '',
                            fromEmail: '',
                            phone: '',
                            subject: '',
                            message: '',
                        }

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
        initGoogleMap() {
            /* global google */
            const mapOptions = {
                // How zoomed in you want the map to start at (always required)
                zoom: 8,

                // The latitude and longitude to center the map (always required)
                center: new google.maps.LatLng(46.281542, -72.465921),

                // How you would like to style the map.
                // This is where you would paste any style found on Snazzy Maps.
                styles: [
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
                    },
                    {
                        featureType: 'landscape',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#ffffff' }, { lightness: 17 }],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 18 }],
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 16 }],
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{ color: '#dedede' }, { lightness: 21 }],
                    },
                    {
                        elementType: 'labels.text.stroke',
                        stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
                    },
                    {
                        elementType: 'labels.text.fill',
                        stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
                    },
                    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#fefefe' }, { lightness: 20 }],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
                    },
                ],
            }

            // Get the HTML DOM element that will contain your map
            // We are using a div with id="map" seen below in the <body>
            const mapElement = document.getElementById('map')

            // Create the Google Map using our element and options defined above
            const map = new google.maps.Map(mapElement, mapOptions)

            // Let's also add a marker while we're at it
            const mapIconImg = {
                url: '/static/img/pin-2.svg',
                size: new google.maps.Size(17 * 2, 22 * 2),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 12),
            }
            new google.maps.Marker({
                position: new google.maps.LatLng(45.463200, -73.595130),
                map,
                title: 'St-Denis Thompson - Mtl',
                icon: mapIconImg,
            })
            new google.maps.Marker({
                position: new google.maps.LatLng(46.819950, -71.278420),
                map,
                title: 'St-Denis Thompson - Qc',
                icon: mapIconImg,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.footer {
    display: none;
}

.map {
    position: relative;
}

.ph {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;
}

#map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
</style>
